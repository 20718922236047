
<template>
  <div class="informationList">
    <div class="top width_1200">
      <div class="li_box">
        <span
          v-for="(rl, i) in tabs"
          :key="i"
          :class="contactsIndex == rl.Id ? 'active' : ''"
          @click="contactsClick(rl, i)"
          >{{ rl.CategoryName }}
        </span>
      </div>
    </div>
    <div class="cont width_1200" v-if="list.length > 0">
      <div class="home_row">
        <div
          class="hover_shadow home_col"
          v-for="(item, index) in list"
          :key="index"
          @click="handleTo(item)"
        >
          <img v-lazy="item.CoverImg" alt="" srcset="" />
          <div class="rig">
            <div class="title decimalPoint">
              {{ item.Title }}
            </div>
            <div class="time">
              <div>{{ item.CreateTime | dataYMD }}</div>
              <div v-if="item.ReadCount && item.ReadCount != 0">
                {{ item.ReadCount }}人看过
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width_1200">
        <el-pagination
          v-if="list.length > 0"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="24">
          <no-data></no-data>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { commonQueryNewsCategory, commonQueryNewsList, updateNewsReadCount } from "@/api/home";
import noData from "@/components/noData";
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      contactsIndex: 0,
      tabs: [],
      loading: false,
      finished: false,
      route: {},
      type: ""
    };
  },
  components: {
    noData,
  },
  created () { },
  mounted () {
    this.route = this.$route.query
    this.commonQueryNewsCategory()
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        this.postUpdateNewsReadCount(obj.Id)
        window.open(obj.JumpUrl)
      } else {
        let path = "/informationList/details";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    async init () {
      let parm = {
        CategoryId: this.contactsIndex,
        type: this.type,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      if (this.route.type) {
        parm.type = this.type
      }
      console.log(parm);
      const res = await commonQueryNewsList(parm);
      if (res.success === true) {
        this.list = res.response.data
        this.total = res.response.dataCount
      } else {
        this.$message.error(res.msg);
      }
    },
    //   类型切换
    contactsClick (obj, i) {
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.page = 1;
      this.contactsIndex = obj.Id;
      this.type = obj.Type
      this.init();
    },
    //资讯类别列表
    async commonQueryNewsCategory () {
      const res = await commonQueryNewsCategory();
      if (res.success === true) {
        this.tabs = res.response;
        if (this.route.type) {
          // this.contactsIndex = this.route.type;
          this.tabs.forEach(item => {
            // console.log(item.Id, this.route.type, '')
            if (Number(this.route.type) === item.Id) {
              this.contactsIndex = item.Id;
              this.type = item.Type
            }
          });
        } else {
          this.contactsIndex = this.tabs[0].Id;
          this.type = this.tabs[0].Type
        }
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.informationList {
  // min-height: 100vh;
  // background-color: #fff;
  .top {
    // padding: 0 15px;
    // background-color: #fff;
    margin: 30px auto;
    color: #5b5b5b;
    .li_box {
      display: flex;
    }
    span {
      display: inline-block;
      min-width: 78px;
      text-align: center;
      margin-right: 20px;
      padding: 0 5px;
      line-height: 30px;
      cursor: pointer;
      border-radius: 50px;
      background-color: #fff;
    }
    .active {
      color: #fff;
      background-color: #1288f4;
    }
  }
  .cont {
    padding: 0 15px;
    .home_row {
      padding: 94px 0 24px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;
      .home_col {
        flex: 0 0 570px;
        width: 570px;
        height: 180px;
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;
        background-color: #fff;
      }
    }
    img {
      width: 208px;
      height: 120px;
      flex: 0 0 120px;
      margin: 0 24px 0 30px;
    }
    .rig {
      width: 290px;
      margin-right: 30px;
      .title {
        min-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 14px;
      }
      .time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        color: #999999;
      }
    }
  }
}

/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
.el-pagination {
  text-align: right;
}
</style>